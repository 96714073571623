import { Injectable } from '@angular/core'
import { TOAST_TYPE, ToastComponent, ToastType } from '@shared/components/v1/toast/toast.component'
import { OverlayRef, Overlay } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'

export interface ToastInfoOptions {
  errorText?: string
  type?: ToastType
}

export interface ToastConfigs {
  timeout?: number
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private overlayRef: OverlayRef[] = []

  constructor(private overlay: Overlay) {}

  // Find a better way to supress api response
  private _permanentPathToSupressToastResponse: string[] = []
  private _permanentPathToSupressErrorResponse: string[] = []

  suppressToastAPI: string[] = []

  show(toastInfoOptions: ToastInfoOptions = {}, config: ToastConfigs = {}) {
    this.overlayRef.push(this.overlay.create())

    const portal = new ComponentPortal(ToastComponent)
    const toast = this.overlayRef[this.overlayRef.length - 1].attach(portal)
    const instance = toast.instance
    Object.keys(toastInfoOptions).forEach((key) => {
      toast.setInput(key, toastInfoOptions[key as keyof ToastInfoOptions])
    })

    instance.handleClose.subscribe(() => {
      toast.destroy()
    })

    if (config.timeout) {
      setTimeout(() => {
        instance.close()
      }, config.timeout)
    }
  }

  success(toastInfoOptions: ToastInfoOptions = {}, config: ToastConfigs = { timeout: 5000 }) {
    this.show({ ...toastInfoOptions, type: TOAST_TYPE.SUCCESS }, config)
  }

  error(toastInfoOptions: ToastInfoOptions = {}, config: ToastConfigs = { timeout: 5000 }) {
    this.show({ ...toastInfoOptions, type: TOAST_TYPE.ERROR }, config)
  }

  addPathToSupressToastResponse(path: string) {
    if (this._permanentPathToSupressToastResponse.includes(path)) {
      return
    }
    this._permanentPathToSupressToastResponse.push(path)
  }

  addPathToSupressErrorResponse(path: string) {
    if (this._permanentPathToSupressErrorResponse.includes(path)) {
      return
    }
    this._permanentPathToSupressErrorResponse.push(path)
  }

  removePathToSupressToastResponse(path: string) {
    const index = this._permanentPathToSupressToastResponse.findIndex((val) => val === path)
    this._permanentPathToSupressToastResponse.splice(index, 1)
  }

  removePathToSupressErrorResponse(path: string) {
    const index = this._permanentPathToSupressErrorResponse.findIndex((val) => val === path)
    this._permanentPathToSupressErrorResponse.splice(index, 1)
  }

  isToastShouldBeSuppressed(path: string) {
    // True if we find substring.
    return this._permanentPathToSupressToastResponse.some((val) => path.includes(val))
  }

  isErrorShouldBeSuppressed(path: string) {
    // True if we find substring.
    return this._permanentPathToSupressErrorResponse.some((val) => path.includes(val))
  }
}
