import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IconModule } from '@shared/components/v1/icon/icon.component'

@Component({
  selector: 'app-icon-button',
  standalone: true,
  imports: [IconModule],
  templateUrl: './icon-button.component.html',
})
export class IconButtonComponent {
  @Input() name = 'plus'
  @Input() size = 20
  @Input() disabled = false
  @Input() disableIconName?: string
  @Output() clickButton = new EventEmitter<Event>()
}
