import { Component, EventEmitter, Input, Output, computed } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '@shared/components/v1/icon/icon.component'
import { ButtonComponent } from '@shared/components/v1/button/button.component'
import { IconButtonComponent } from '@shared/components/v1/icon-button/icon-button.component'

export type ToastType = 'success' | 'error' | 'default'
export const TOAST_TYPE = {
  SUCCESS: 'success' as ToastType,
  ERROR: 'error' as ToastType,
  DEFAULT: 'default' as ToastType,
}

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, IconModule, ButtonComponent, IconButtonComponent],
  templateUrl: './toast.component.html',
})
export class ToastComponent {
  TOAST_TYPE = TOAST_TYPE
  @Input() errorText: string = 'เกิดข้อผิดพลาด'
  @Input() type: ToastType = TOAST_TYPE.DEFAULT
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() closeCallback?: Function
  @Output() handleClose = new EventEmitter<void>()

  iconName = computed(() => {
    if (this.type === TOAST_TYPE.ERROR) {
      return 'cancelRed'
    }
    return 'checkCircle'
  })

  close() {
    this.handleClose.emit()
    if (this.closeCallback) {
      this.closeCallback()
    }
  }
}
