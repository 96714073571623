<div class="w-auto max-w-6xl mx-4 fixed top-4 z-1000">
  <div class="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded">
    <div class="p-2 text-center w-[calc(100vw-32px)]">
      <div class="flex">
        <div class="mr-2">
          @if (type !== TOAST_TYPE.DEFAULT) {
            <app-icon [name]="iconName()" [size]="20" />
          }
        </div>
        <div class="text-left w-full text-base">
          {{ errorText }}
        </div>
        <div>
          <app-icon-button name="close" data-test-id="toast-close-button" [size]="16" (clickButton)="close()">
          </app-icon-button>
        </div>
      </div>
    </div>
  </div>
</div>
